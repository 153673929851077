
$(function() {
    $(".df-multiple-checkbox-search").on("keyup keypress", function(e) {
        let keyCode = e.keyCode || e.which
        if (keyCode === 13) {
            e.preventDefault()
            return false
        }
        const filterValue = $(this).val().toLowerCase()
        const valuesList = $(".df-values-list")

        $(valuesList).children("div").each(function(index, node) {
            const self = $(node)
            const element = $(node).children("label")
            let txtValue = element.text()
            if (txtValue.toLowerCase().indexOf(filterValue) > -1) {
                self.show()
            } else {
                self.hide()
            }
        })
    })
})
