import UIkit from "uikit";

export function properties_presets(body_tag) {
    if (body_tag.hasClass("js-properties js-properties-presets")) {
        $("a.js-delete-property-preset").on("click", function(e) {
            let self = $(this)
            e.preventDefault();
            e.target.blur();
            let message = $(this).data('message');
            UIkit.modal.confirm(message).then(function () {
                window.location = self.attr("href");
            }, function() {
                // Reject
            });
        });
    }

    if (body_tag.hasClass("js-properties js-properties-manage-preset")) {
        // При переносе характеристики требуется
        UIkit.util.on('.js-available-properties-list', 'added', function(item) {
            let ul_item = $(item.target)
            let li_item = $(item.detail[1])

            if (ul_item.length > 0 && li_item.length > 0) {
                // Display blocks
                li_item.find('input.js-property-id').attr('name', '');
                li_item.find('input.js-use-as-filter-input').attr('name', '');

                li_item.find('.js-use-as-filter-block').hide();
            }
        })
        // Перенесли характеристику в preset
        UIkit.util.on('.js-preset-properties-list', 'added', function(item) {
            let ul_item = $(item.target)
            let li_item = $(item.detail[1])

            if (ul_item.length > 0 && li_item.length > 0) {
                // Display blocks
                li_item.find('input.js-property-id').attr('name', 'properties[]');
                li_item.find('input.js-use-as-filter-input').attr('name', 'use_as_filter[]');

                li_item.find('.js-use-as-filter-block').show();
            }
        });

        $('input.js-properties-filter-input').on("keyup change", function(e) {
            let self_input = $(this);
            let query = self_input.val();
            let list_element = $('ul.js-available-properties-list');

            if (e.keyCode === 27) {
                self_input.val("")
                query = ""
            }

            $.expr[":"].contains = $.expr.createPseudo(function(arg) {
                return function(elem) {
                    return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) > 0;
                }
            })

            if (list_element.length > 0 && query.length > 0) {
                $("li:not(:Contains(" + query + "))", list_element).each(function() {
                    $(this).hide()
                })
                $("li:Contains(" + query + ")", list_element).each(function() {
                    $(this).show()
                })
                //$('li', list_element).each(function() {
                //    console.log(1)
                //})
            } else {
                $("li", list_element).each(function() {
                    $(this).show()
                })
            }
        })
    }
}