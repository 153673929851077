'use strict'

export function pofc(DFApp) {
    if (!DFApp.body.hasClass("js-pofc-select_products"))
        return;

    $("input[type=file].js-uploader-products-offer").each(function (ind, elem) {
        $(elem).drop_uploader({
            uploader_text: 'Drop files to upload, or',
            browse_text: 'Browse',
            only_one_error_text: 'Only one file allowed',
            not_allowed_error_text: 'File type is not allowed',
            big_file_before_error_text: 'Files, bigger than',
            big_file_after_error_text: 'is not allowed',
            allowed_before_error_text: 'Only',
            allowed_after_error_text: 'files allowed',
            browse_css_class: 'button button-primary',
            browse_css_selector: 'file_browse',
            uploader_icon: '<i class="pe-7s-cloud-upload"></i>',
            file_icon: '<i class="pe-7s-file"></i>',
            progress_color: '#4a90e2',
            time_show_errors: 5,
            layout: 'list',
            method: 'normal'
        });
    })

    $('.slect_pofs_prod').on('change', function (e) {
        var $t = $(this);
        var status = $t.prop('checked');
        $.post({
            url: $t.data('link'),
            data: {cmd: (status ? 'on' : 'off')},
            success: function(data) {
                $('.slect_pofs_prod-total').text(data.selected);
            }
        })
    })
}