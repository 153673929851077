/* JS для работы со страницами ролей и прав доступа. */
import UIkit from "uikit";

export function category_properties(body_tag) {
    if (body_tag.hasClass("js-categories js-categories-properties")) {
        $("a.js-delete-category-property-group").on("click", function(e) {
            let self = $(this)
            e.preventDefault();
            e.target.blur();
            let message = $(this).data('message');
            UIkit.modal.confirm(message).then(function () {
                window.location = self.attr("href");
            }, function() {
                // Reject
            });
        });
    }

    if (body_tag.hasClass("js-categories js-categories-properties-layout")) {
        UIkit.util.on('.js-grouped-properties-lists', 'added', function(item) {
            // Поменялась группа
            let ul_item = $(item.target)
            let li_item = $(item.detail[1])

            let preset_id = ul_item.data('preset-id');

            if (ul_item.length > 0 && li_item.length > 0) {
                li_item.find('input[type=hidden].js-property-preset-id').prop('name', 'presets[' + preset_id + ']');
                li_item.find('input[type=checkbox].js-property-use-as-filter').prop('name', 'use_as_filter[' + preset_id + ']');
                li_item.find('input[type=hidden].js-property-mark-to-remove1').prop('name', 'mark_to_remove[' + preset_id + ']');
            }
        });

        // Пометка удаления
        // Просто переключаем соседний чекбокс
        $("a.js-mark-property-to-delete").on("click", function() {
            let check = $('input[type=checkbox]', $(this).parent());

            if (check.length > 0) {
                check.click()
            }
        })
    }
}