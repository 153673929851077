import UIkit from "uikit";

(function ($) {

    let $container, $input;

    $.fn.layout_widget = function (options) {
        $container = $(this)
        $input = $(options.input)

        try {
            for(let item of JSON.parse($input.val())) {
                let $item = $container.find('#' + item).parents('.sorting-item');
                $item.detach()
                $container.append($item)
            }
        } catch (ex) {
            console.log(ex)
        }

        UIkit.util.on($container, 'stop', function () {
            let ret = [];
            $container.find('.sorting-item').each(function () {
                let item = $(this).find('[id]').attr('id');
                if(item)
                    ret.push(item)
            })
            $input.val(JSON.stringify(ret))
        });
    }

}(jQuery));
