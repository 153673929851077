/* JS для работы со страницами ролей и прав доступа. */
import UIkit from "uikit";
import { drop_uploader } from "../forks/dropuploader"

export function products_images(DFApp) {
    'use strict'
    // TODO: Globally?
    $(document).ajaxError(function(event, jqXHR, settings, thrownError) {
                    if (jqXHR && jqXHR.responseJSON && jqXHR.responseJSON.message) {
                        DFApp.notify(jqXHR.responseJSON.message, 'danger')
                    }
                })

    if (DFApp.body.hasClass("js-products js-products-manage-images") ||
        DFApp.body.hasClass("js-products js-products-manage-image")) {
        $("input[type=file].js-uploader-multiple-images").each(function(ind, elem) {
            let self = $(elem);
            self.drop_uploader({
                accept: 'image/jpg,image/jpeg,image/png,image/webp',
                uploader_text: 'Drop files to upload, or',
                browse_text: 'Browse',
                only_one_error_text: 'Only one file allowed',
                not_allowed_error_text: 'File type is not allowed',
                big_file_before_error_text: 'Files, bigger than',
                big_file_after_error_text: 'is not allowed',
                allowed_before_error_text: 'Only',
                allowed_after_error_text: 'files allowed',
                browse_css_class: 'button button-primary',
                browse_css_selector: 'file_browse',
                uploader_icon: '<i class="pe-7s-cloud-upload"></i>',
                file_icon: '<i class="pe-7s-file"></i>',
                progress_color: '#4a90e2',
                time_show_errors: 5,
                layout: 'thumbnails',
                method: 'normal'
            });
        });

        $("input[type=file].js-uploader-single-image").each(function(ind, elem) {
            let self = $(elem);
            self.drop_uploader({
                uploader_text: 'Перетащите сюда изображение для загрузки или',
                browse_text: 'выберете вручную',
                only_one_error_text: 'Можно загрузить только один файл',
                not_allowed_error_text: 'File type is not allowed',
                big_file_before_error_text: 'Files, bigger than',
                big_file_after_error_text: 'is not allowed',
                allowed_before_error_text: 'Only',
                allowed_after_error_text: 'files allowed',
                browse_css_class: 'button button-primary',
                browse_css_selector: 'file_browse',
                uploader_icon: '<i class="pe-7s-cloud-upload"></i>',
                file_icon: '<i class="pe-7s-file"></i>',
                progress_color: '#4a90e2',
                time_show_errors: 5,
                layout: 'thumbnails',
                method: 'normal'
            });
        })

        $(document).on("click", ".js-remove-file-assoc", function(e) {
        // $(".js-remove-file-assoc").on("click", function(e) {
            e.preventDefault()
            self = $(this)
            $.ajax({
                method: 'post',
                url: self.data("url"),
                success: function (data) {
                    self.closest("tr").remove()
                },
                error: function (data) {
                    alert(data.responseText)
                }
            });
        });
    }
    if (DFApp.body.hasClass("js-products js-products-manage-files") ||
        DFApp.body.hasClass("js-products js-products-manage-product-file")
    ) {
        $("input[type=file].js-uploader-multiple-files").each(function(ind, elem) {
            let self = $(elem);
            self.drop_uploader({
                uploader_text: 'Перетащите сюда файл для загрузки или',
                browse_text: 'выберете вручную',
                only_one_error_text: 'Можно загрузить только один файл',
                not_allowed_error_text: 'File type is not allowed',
                big_file_before_error_text: 'Files, bigger than',
                big_file_after_error_text: 'is not allowed',
                allowed_before_error_text: 'Only',
                allowed_after_error_text: 'files allowed',
                browse_css_class: 'button button-primary',
                browse_css_selector: 'file_browse',
                uploader_icon: '<i class="pe-7s-cloud-upload"></i>',
                file_icon: '<i class="pe-7s-file"></i>',
                progress_color: '#4a90e2',
                time_show_errors: 5,
                layout: 'list',
                method: 'normal'
            });
        })

        $(document).on("click", ".js-remove-file-assoc", function(e) {
        // $(".js-remove-file-assoc").on("click", function(e) {
            e.preventDefault()
            self = $(this)
            $.ajax({
                method: 'post',
                url: self.data("url"),
                success: function (data) {
                    self.closest("tr").remove()
                },
                error: function (data) {
                    alert(data.responseText)
                }
            });
        });
    }

    if (DFApp.body.hasClass("js-test-image-uploader")) {
        // Initialize the jQuery File Upload widget:
        //$("input[type=file].js-uploader-multiple-images").each(function(index, elem) {
        //    let self = $(elem)
        //    console.log(self.data('delete-url'))
        //    self.drop_uploader({
        //        uploader_text: 'Drop files to upload, or',
        //        browse_text: 'Browse',
        //        only_one_error_text: 'Only one file allowed',
        //        not_allowed_error_text: 'File type is not allowed',
        //        big_file_before_error_text: 'Files, bigger than',
        //        big_file_after_error_text: 'is not allowed',
        //        allowed_before_error_text: 'Only',
        //        allowed_after_error_text: 'files allowed',
        //        browse_css_selector: 'file_browse',
        //        uploader_icon: '<i class="pe-7s-cloud-upload"></i>',
        //        file_icon: '<i class="pe-7s-file"></i>',
        //        progress_color: '#4a90e2',
        //        time_show_errors: 5,
        //        layout: 'thumbnails',
        //        method: 'normal',
        //        //url: self.data('url'),
        //        //delete_url: self.data('delete-url'),
        //    });
        //});
    }
}