import jQuery from "jquery";

import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons.min";

UIkit.use(Icons)

import Inputmask from "inputmask";

import "scrolltofixed/jquery-scrolltofixed-min";

import "./forks/jquery.stickytableheaders";
//import "./forks/dropuploader";
import "./widgets/jquery.checktree";
import "./widgets/modelfield";
import "devbridge-autocomplete/dist/jquery.autocomplete.min";
import "spectrum-colorpicker/spectrum";
import "./filemanager";

import { rbac_main } from './rbac/rbac';
import { category_properties } from './categories/properties';
import { properties_presets } from "./properties/presets";
import { products_features } from "./products/features";
import { products_images } from "./products/images";
import { products_varieties } from "./products/varieties";
import { products_stickers } from "./products/stickers";
import { products_files } from "./products/files";
import { pofc } from "./pofc/pofc";
import { faqs } from "./faqs/faqs";
import { faqs_index } from "./faqs/index";
import { copy_to_form } from "./copy_to_form";
import { helpdesk_index } from "./helpdesk/index";
import { helpdesk_manage } from "./helpdesk/manage";
import { manuals_index } from "./manuals/index";
import { manuals_manage } from "./manuals/manage";
import { banners } from "./banners/banners";

import "./editor"
import "./fields"

$('document').ready(function(e) {
    $(".loader").fadeOut("slow");
});

$(function() {
    const df_admin = new DefenderAdmin();

    df_admin.bindFlashedMessages()
    df_admin.bindAutocomplete()
    df_admin.bindGroupedCheckboxes()
    df_admin.bindBottomBar()
    df_admin.bindStickyTable()
    df_admin.bindCheckTree()
    df_admin.bindColorInput()
    df_admin.bindTags()
    df_admin.bindDraftLink()
    df_admin.bindCheckTerritories()
    df_admin.bindSelectAll()

    df_admin.bindSingleImageUpload()
    df_admin.bindSVGUpload()
})

function DefenderAdmin() {
    this.body = $("body");
    rbac_main(this.body);
    category_properties(this.body);
    properties_presets(this.body);
    products_features(this);
    products_images(this);
    products_stickers(this);
    products_varieties(this);
    products_files(this);
    pofc(this);
    faqs(this);
    faqs_index(this);
    copy_to_form();
    helpdesk_index(this);
    helpdesk_manage(this);
    manuals_index(this);
    manuals_manage(this);
    banners(this);
}

DefenderAdmin.prototype.notify = function(message, category) {
    UIkit.notification({
        message: message,
        status: category,
        pos: 'top-right'
    })
}

DefenderAdmin.prototype.bindCheckTerritories = function() {
    $(".js-select-only-rus").on('click',function(e) {
        e.preventDefault()
        let ul_el = $('.uk-list .js-territories-list');

        $('input.js-territory-checkbox').each(function() {
            if (["RU", "BY", "UZ", "KZ", "142"].includes($(this).val())) {
                $(this).prop("checked", true);
            } else {
                $(this).prop("checked", false)
            }
        })
    })
}

DefenderAdmin.prototype.bindFlashedMessages = function() {
    let glob = this;
    $("[data-df-flashed-message]").each(function() {
        let self = $(this)
        glob.notify(self.data("message"), self.data("category"))
    });
}

DefenderAdmin.prototype.bindDraftLink = function() {
    $("[data-df-draft]").each(function(i, el) {
        let self = $(this)
        $(el).click(function() {
            // Сброс "человеческий перевод" если есть
            let is_g = $(el).data('clear-check')
            if (is_g !== undefined) {
                let fld = $("#" + is_g)
                if (fld) {
                    $(fld).prop("checked", false)
                }
            }

            let field = $(el).data('field')
            let val = $(el).data('value')
            let value = $(el).html()
            if (val !== undefined) {
                value = val
            }
            $('#' + field).val(value).focus()
        })
    })
}

DefenderAdmin.prototype.bindAutocomplete = function() {
    $("[data-df-autocompleteproduct_simple]").each(function(i, el) {
        let $this = self = $(this);

        $this.on("keydown", function(e) {
            if (e.keyCode === 13) {
                e.preventDefault()
                return false;
            }
        })

        $("body").on("click", "a.js-remove-from-list", function(e) {
            $(this)[0].closest("tr").remove()
            e.preventDefault();
        });


        let options = {
            serviceUrl: $this.data("url"),
            previewBadQueries: false,

            onSelect: function(val) {
                let tbody = $(self.data("table_id") + " > tbody")

                $('<tr><td>' + val.data +
                    '<input type="hidden" name="selproducts[]" value="' + val.id + '" /></td>' +
                    '<td><a target="_blank" href="' + val.product_url + '">' + val.title + '</a></td>' +
                    '<td><a href="#" class="js-remove-from-list uk-button uk-button-small uk-button-danger">Убрать</a></td>' +
                    '</tr>').prependTo(tbody);

                self.val('');
                self.focus();
            }
        }
        $(el).autocomplete(options)
    });
    $("[data-df-featurelink]").each(function(i, el) {
        let $this = self = $(this);

        $this.on("keydown", function(e) {
            if (e.keyCode === 13) {
                e.preventDefault()
                return false;
            }
        })

        let $target = $($this.attr("data-target"));
        let $target_value = $($this.attr("data-target_value"));

        let options = {
            serviceUrl: $this.data("url"),
            // при true: "E.g. if Jam returns no suggestions,
            // it will not fire for any future query that starts with Jam"
            preventBadQueries: false,

            onSelect: function (val) {
                let tbody = $(self.data('table_id') + ' > tbody')

                $.ajax({
                    url: self.data("add-url"),
                    data: {
                        description_id: val.id,
                        header_id: val.id,
                    },
                    method: "POST",
                    success: function (data, textStatus, jqXHR) {
                        if (jqXHR.status === 201) {
                            if (tbody) {
                                let rm_html = "<small class=\"uk-text-muted\">Убрать можно будет после обновления страницы</small>"
                                if (val.remove_url) {
                                    rm_html = '<button type="button" class="js-remove-file-assoc uk-button uk-button-danger"\n' +
                                        '                                    data-url="' + val.remove_url + '"' +
                                        '                                    >Убрать</button>'
                                }

                                $('<tr><td>' + val.title + '</td>' +
                                    '<td>' + rm_html + '</td>' +
                                    '</tr>').appendTo(tbody);

                            }
                            let total = $("#total-linked-descriptions")
                            if (total && data.total) {
                                total.html(data.total)
                            }
                        } else if (jqXHR.status === 200) {
                            if (data.errorCode) {
                                if (data.errorCode === -1) {
                                    alert("Уже добавлен")
                                }
                            }
                        }

                        self.val('');
                        self.focus();
                    }
                })
            }
        }
        $(el).autocomplete(options)

    });
    $("[data-df-autocompleteproduct]").each(function(i, el) {
        let $this = self = $(this);

        $this.on("keydown", function(e) {
            if (e.keyCode === 13) {
                e.preventDefault()
                return false;
            }
        })

        let $target = $($this.attr("data-target"));
        let $target_value = $($this.attr("data-target_value"));

        let options = {
            serviceUrl: $this.data("url"),
            // при true: "E.g. if Jam returns no suggestions,
            // it will not fire for any future query that starts with Jam"
            preventBadQueries: false,

            onSelect: function (val) {
                let tbody = $(self.data('table_id') + ' > tbody')

                $.ajax({
                    url: self.data("add-url"),
                    data: {
                        product_id: val.id,
                        ref_product_id: self.data("ref_product_id")
                    },
                    method: "POST",
                    success: function (data, textStatus, jqXHR) {
                        if (tbody) {
                            let adding = self.data('product_id') == val.id ? false : true;
                            if (adding) {
                                let rm_html = "<small class=\"uk-text-muted\">Убрать товар можно будет после обновления страницы</small>"
                                if (val.remove_url) {
                                    rm_html = '<button type="button" class="js-remove-file-assoc uk-button uk-button-danger"\n' +
                                        '                                    data-url="' + val.remove_url + '"' +
                                        '                                    >Убрать файл из товара</button>'
                                }
                                $('<tr><td>' + val.data + '</td>' +
                                    '<td><a href="' + val.product_url + '">' + val.title + '</a></td>' +
                                    '<td>' + rm_html + '</td>' +
                                    '</tr>').prependTo(tbody);
                            }
                            self.val('');
                            self.focus();
                        }
                    }
                })
            }
        }
        $(el).autocomplete(options)
    })
    $("[data-df-textautocomplete]").each(function(i, el) {
        let $this = $(this);

        let $target = $($this.attr("data-target"));
        let $target_value = $($this.attr("data-target_value"));

        let options = {
            serviceUrl: $this.data("url"),
            // при true: "E.g. if Jam returns no suggestions,
            // it will not fire for any future query that starts with Jam"
            preventBadQueries: false,
            onSelect: function (val) {
                $target.each(function () {
                    $(this).val(val.data)
                })
                $target_value.each(function () {
                    $(this).val(val.value)
                })
            }
        }
        $(el).autocomplete(options)
    })
}

DefenderAdmin.prototype.bindStickyTable = function() {
    $("table[data-df-stickytableheader]").stickyTableHeaders({
        fixedOffset: $("#js-main-navbar")
    });
    $(window).trigger("resize.stickyTableHeaders");

    let resizeTimer = false;

    $(window).on("resize", function() {
        if (!resizeTimer) {
            $(window).trigger("resizestart");
        }
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {
            $(window).trigger("resizeend")
        }, 250);
    }).on("resizeend", function() {
        $(window).trigger('resize.stickyTableHeaders');
    })
}

DefenderAdmin.prototype.bindBottomBar = function() {
    $("[data-df-bottomtoolbar]").each(function(e, item) {
        $(item).scrollToFixed({
            bottom: 0,
            limit: 0,  // $(item).offset().top,
            zIndex: 999,
            className: 'uk-navbar-transparent'
        })
    });
}

DefenderAdmin.prototype.bindCheckTree = function() {
    $("ul[data-checktree]").checktree();
}

DefenderAdmin.prototype.bindGroupedCheckboxes = function() {
    // Программная иммитация радио-переключателей на основе класса js-group-checkbox и data-group-name
    $("input[type=checkbox][data-group-checkbox]").on('change', function() {
        let group = $(this).data('group-name')
        self = this
        if (this.checked) {
            $('input[type=checkbox][data-group-checkbox][data-group-name=' + group + ']').each(function() {
                if (this !== self) {
                    $(this).prop('checked', false);
                }
            })
        }
    })
}

DefenderAdmin.prototype.bindColorInput = function() {
    let default_attrs = {
        preferredFormat: "hex",
        showInput: true,
        allowEmpty: true
    }
    $("input[data-color]").each(function() {
        let self = $(this);

        default_attrs["color"] = self.attr("value")
        default_attrs["change"] = function(color) {
            if (color) {
                self.attr('value', color.toHexString());
            } else {
                self.attr('value', '');
            }
        }

        self.spectrum(default_attrs)
    })
}

DefenderAdmin.prototype.bindTags = function () {
    $("div.tags").each(function () {
        let $container = $(this);
        let $select = $container.find('select')

        function set_btn_state(btn, toggle) {
            let $btn = $(btn);
            let value = $btn.data('value');
            let $option = $select.find('option[value="' + value + '"');
            let state = $option.prop('selected')

            if(toggle) {
                state = !state;
                $option.prop('selected', state)
            }
            if(state) {
                $btn.addClass('uk-button-primary')
                $btn.removeClass('uk-button-default')
            } else {
                $btn.addClass('uk-button-default')
                $btn.removeClass('uk-button-primary')
            }
        }

        $container.find('div[data-value]').each(function () {
            set_btn_state(this, false);
        })

        $container.find('div[data-value]').on('click', function () {
            set_btn_state(this, true);
        })

    })
}

DefenderAdmin.prototype.bindSelectAll = function() {
    $(":checkbox[data-multiselect]").each(function(idx, elem) {
        let self = $(elem)
        let _cls = self.data('multiselect')
        if (_cls) {
            self.on("change", function(e) {
                $(":checkbox." + _cls).each(function(i, el) {
                    $(el).attr("checked", self.is(":checked"))
                })
            })
        }
    })
}

DefenderAdmin.prototype.bindSingleImageUpload = function() {
    $("input[type=file].js-uploader-single-image").each(function(ind, elem) {
        let self = $(elem);
        self.drop_uploader({
            accept: 'image/jpg,image/jpeg,image/png,image/webp',
            uploader_text: 'Перетащите сюда изображение для загрузки или',
            browse_text: 'выберете вручную',
            only_one_error_text: 'Можно загрузить только один файл',
            not_allowed_error_text: 'File type is not allowed',
            big_file_before_error_text: 'Files, bigger than',
            big_file_after_error_text: 'is not allowed',
            allowed_before_error_text: 'Only',
            allowed_after_error_text: 'files allowed',
            browse_css_class: 'button button-primary',
            browse_css_selector: 'file_browse',
            uploader_icon: '<i class="pe-7s-cloud-upload"></i>',
            file_icon: '<i class="pe-7s-file"></i>',
            progress_color: '#4a90e2',
            time_show_errors: 5,
            layout: 'thumbnails',
            method: 'normal'
        });
    })
}


DefenderAdmin.prototype.bindSVGUpload = function() {
    const uploaders = $(".js-svg-upload")
    const previewArea = $(".js-svg-preview")

    uploaders.each(function(num, elem) {
        const textArea = $("#" + $(elem).data("preview-textarea"))
        UIkit.upload(elem, {
            url: $(elem).data("url"),
            type: 'html',
            method: 'POST',
            allow: '*.svg',
            beforeSend: function(environment) {
                if (textArea) {
                    textArea.prop("disabled", true)
                }
            },
            error: function () {
                console.log("error", arguments)
            },
            completeAll: function(resp) {
                console.log("complete", arguments, resp)
                if (textArea) {
                    $(textArea).html(resp.responseText)
                    textArea.prop("disabled", false)
                }
                if (previewArea) {
                    $(previewArea).html(resp.responseText);
                }
            }
        })
    })
}


$(function() {
    Inputmask({"mask": "99.99.9999", "inputFormat": "dd.mm.yyyy"}).mask(".js-date-input")

    $(".js-preview-button").on("click", function (e) {
        // e.preventDefault()
        let sel = $("select[name=df-preview-links]").find(":selected").val()

        if (sel) {
            $(this).attr("href", sel)
        }
    })

    $("a.js-delete-confirmation-button").on("click", function(e) {
        const self = $(this)

        if (confirm(self.data("confirm"))) {
            return true
        } else {
            e.preventDefault()
            return false
        }
    })
})
