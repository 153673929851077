'use strict'

export function helpdesk_manage(DFApp) {
    if (!DFApp.body.hasClass("js-helpdesk-manage"))
        return;

    let $product_id = $('#product_id');
    let $model_search = $('#model_search');

    function setup_model_controls(val) {
        if (!val || !val.data)
            val = {data: null}

        $product_id.val(val.data);
        $model_search.removeClass('uk-form-danger');

        let $a_model_link = $('#a_model_link');
        if (val.data) {
            $model_search.val(val.value);
            $a_model_link.removeClass('uk-hidden');
            $a_model_link.attr('href', val.url);
        } else {
            $model_search.val('');
            $a_model_link.addClass('uk-hidden');
        }
    }

    $('#model_clear').on('click', function (e) {
        e.preventDefault();
        setup_model_controls({data: null})
    })

    $model_search.autocomplete({
        serviceUrl: '/helpdesk/ajax/search_products',
        // при true: "E.g. if Jam returns no suggestions,
        // it will not fire for any future query that starts with Jam"
        preventBadQueries: false,
        onSelect: setup_model_controls
    })

    $model_search.on('change', function () {
        $model_search.addClass('uk-form-danger');
    })

    $.ajax({
        url: '/helpdesk/ajax/get_product/' + $product_id.val(),
        success: setup_model_controls
    })
}