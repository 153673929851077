(function($){
    $.fn.checktree = function(){
        function checked(elem, initial) {
            let clk_checkbox = elem,
            chk_state = clk_checkbox.is(':checked'),
            parent_li = clk_checkbox.closest('li'),
            parent_uls = parent_li.parents('ul');
            if (!initial) {
                parent_li.find(':checkbox').prop('checked', chk_state);
            }
            parent_uls.each(function() {
                let parent_ul = $(this);
                let parent_state = (parent_ul.find(':checkbox').length === parent_ul.find(':checked').length);
                parent_ul.siblings(':checkbox').prop('checked', parent_state);
            });
        }

        $(':checkbox', this).each(function(e) {
            checked($(this), true)
        })

        $(':checkbox', this).on('change', function (event){
            event.stopPropagation();
            checked($(this))
         });
    };
}(jQuery));
