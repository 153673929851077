import UIkit from "uikit";

export function copy_to_form() {

    let where = $('#copy_to-where'); // select с выбором пункта назначения
    let $form = where.closest('form'); // форма со нашими данными
    let exists_warning = $('#copy_to-form_warning'); // текст о существовании такого же текста в новой сущности
    // Кнопка копирования. Будем запрещать копирование до проверки
    let do_copy_button = $form.find('button[name=copy_to_submit]');

    function toggle_prop_type_select() {
        let prop_type = where.val();
        // Если выбираем копировать в свойство - показываем выбор типа этого нового свойства
        $('#copy-to-form-prop-type').toggle(prop_type == 'properties')

        exists_warning.hide();
        do_copy_button.prop('disabled', true);

        // Проверяем существование такого текста в назначении
        $.ajax({
            method: 'post',
            url: window.location, // идем в ту же локацию
            // Дополнительно сериализуем флаги "мы копируем (а не сохраняем)" и "что просто проверяем"
            data: $form.serialize() + '&copy_to_submit=true&copy_to-check_exists=true',
            success: function (data) {
                // на данные момент возвращаем просто текст 'exists' или пусто
                if(data == 'exists')
                    exists_warning.show();
                do_copy_button.prop('disabled', false);
            },
            // error: function () {}
        });
    }
    // Вызываем проверку на показ формы
    UIkit.util.on('#copy-to-form', 'show', function () {
        toggle_prop_type_select();
    });
    // ... и на переключение select-а
    where.on('change', toggle_prop_type_select);
}