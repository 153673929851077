'use strict'

import UIkit from "uikit";
import "./l10n_modal";
import "./comics_widget";
import "./layout_widget";

export function manuals_manage(DFApp) {
    if (!DFApp.body.hasClass("js-manuals-manage"))
        return;

    // Выбор главного изображения
    $(".js-select-image").on("click", function(e) {
        e.preventDefault()
        let $image_id = $("input#image_id")

        if ($image_id) {
            $image_id.val($(this).data("image-id"))

            $(".js-select-image").each(function() {
                $(this).parent("li").removeClass("uk-active")
            })
            $(this).parent("li").addClass("uk-active")
        }
    })

    // let $form = $('#manual_edit');
    // let $product_id = $('#product_id');
    // let $select_image = $('#select_image');
    //
    // // начальная загрузка и выбор фронтальной картинки
    //
    // let $front_image = $('#front_image');
    //
    // $.get({
    //     url: '../images',
    //     data: {image_id: $('#image_id').val()},
    //     success: function (data) {
    //         $front_image.find('img').attr('src', data)
    //     }
    // })
    //
    // $front_image.on('click', function () {
    //     $select_image.data('url', `../images?product_id=${$product_id.val()}`)
    //     $select_image.data('on_image_selected', function (image_id, image_src) {
    //         $('#image_id').val(image_id);
    //         $front_image.find('img').attr('src', image_src)
    //     })
    //     UIkit.modal($select_image).show();
    // })
    //
    // // диалог выбора картинок
    //
    // UIkit.util.on($select_image, 'show', function () {
    //     $.get({
    //         url: $select_image.data('url'),
    //     }).done(function (data) {
    //         if (!data)
    //             return;
    //         let $container = $select_image.find('.uk-modal-body .uk-thumbnav').empty();
    //         data.forEach(function (image_data) {
    //             let image_id = image_data[0];
    //             let image_src = image_data[1];
    //             let d1 = $('<li>').attr('data-image-id', image_id).appendTo($container);
    //             let d15 = $('<div class="df-image-thumbnail">').appendTo(d1)
    //             let d2 = $('<a href="#">').appendTo(d15);
    //             $('<img>').attr('src', image_src).appendTo(d2)
    //
    //             d1.on('click', function () {
    //                 $select_image.data('on_image_selected')(image_id, image_src);
    //                 UIkit.modal($select_image).hide();
    //             })
    //
    //             let delete_url = $select_image.data('delete_url');
    //             if (delete_url) {
    //                 let $close = $('<div class="close">close</div>').appendTo(d15)
    //                 $close.on('click', function () {
    //                     $.ajax({
    //                         url: `${delete_url}/${image_id}`,
    //                         method: 'DELETE',
    //                         success: function () {
    //                             d1.remove();
    //                         }
    //                     })
    //                     return false;
    //                 })
    //             }
    //         })
    //     });
    //
    // });
    //
    // локали

    let $locale = $('#locale') // input с основным языком
    let $locales = $('[name=locales]'); // чекбоксы с локалями
    let $toggle_all_locales = $('.toggle-all-locales'); // чекбокс - "все локали"
    //
    // сменился основной язык - деактивируем чекбокс, остальные активируем
    function on_main_locale_changed() {
        $locales.prop('disabled', false)
        $locales.filter('[value=' + $locale.val() + ']').prop('checked', true).prop('disabled', true)
    }

    $locale.on('change', function () {
        on_main_locale_changed()
    })
    on_main_locale_changed()

    // включаем чекбокс "все языки", если выбраны все языки, иначе отключаем
    function set_$toggle_all_locales() {
        $toggle_all_locales.prop('checked', $locales.length == $locales.filter(':checked').length)
    }

    set_$toggle_all_locales()
    // включаем/выключаем все чекбоксы, кроме основного языка, он деактивирован
    $toggle_all_locales.on('change', function () {
        $locales.filter(':not(:disabled)').prop('checked', $(this).prop('checked'))
    })

    // // диалог перевода
    //
    // let locales = []
    // $locales.each(function () {
    //     let $checkbox = $(this);
    //     locales.push({val: $checkbox.val(), label: $checkbox.next().html()})
    // })
    // $('#l10n_modal').l10n_modal('init', {
    //     locales,
    // });
    //
    // function l10n_show_modal(options) {
    //     $('#l10n_modal').l10n_modal('show', options);
    // }
    //
    // // справочники
    //
    // $('a.dictionary_item_edit').on('click', function () {
    //     let $t = $(this);
    //     let url = `../ajax_dictionary_item/${$t.data('dictionary')}/${$t.data('code')}`;
    //     $.get({
    //         url: url,
    //         success: function (data) {
    //             l10n_show_modal({
    //                 data,
    //                 title: $t.html(),
    //                 on_submit(data) {
    //                     $.post({
    //                         url: url,
    //                         data: JSON.stringify(data),
    //                         contentType: "application/json",
    //                     })
    //                 }
    //             })
    //         }
    //     })
    //     return false;
    // });
    //
    // // справочники 2
    //
    // $('div[data-dictionary]').each(function () {
    //     let $widget = $(this);
    //     let $select = $widget.find('select');
    //     let dictionary = $widget.data('dictionary')
    //
    //     function build_select(data) {
    //         $select.children().remove()
    //         $.each(data, function (i, val) {
    //             $('<option>').val(val[0]).html(val[1]).appendTo($select).attr('title', val[2])
    //             if (val[1].startsWith('[*]'))
    //                 $select.val(val[0])
    //         })
    //     }
    //
    //     let url = `../ajax_dictionary/${dictionary}`;
    //     $.get({
    //         url: url,
    //         success: build_select
    //     })
    //
    //     $widget.find('a.add-item').on('click', function () {
    //
    //         l10n_show_modal({
    //             data: {},
    //             title: $widget.find('label:eq(0) a').html(),
    //             on_submit(data) {
    //                 $.post({
    //                     url: `../ajax_dictionary/${dictionary}`,
    //                     data: JSON.stringify(data),
    //                     contentType: "application/json",
    //                     success: build_select
    //                 })
    //             }
    //         })
    //
    //         return false;
    //     })
    //
    //     $widget.find('a.edit-item').on('click', function () {
    //         let url = `../ajax_dictionary_item/${$select.val()}`;
    //         $.get({
    //             url: url,
    //             success: function (data) {
    //                 l10n_show_modal({
    //                     data,
    //                     title: $widget.find('label:eq(0) a').html(),
    //                     on_submit(data) {
    //                         $.post({
    //                             url: url,
    //                             data: JSON.stringify(data),
    //                             contentType: "application/json",
    //                         })
    //                     }
    //                 })
    //             }
    //         })
    //         return false;
    //     })
    //
    //     $widget.find('a.del-item').on('click', function () {
    //         $.ajax({
    //             url: `../ajax_dictionary_item/${$select.val()}`,
    //             type: 'DELETE',
    //             success: build_select
    //         })
    //         return false;
    //     })
    //
    //     $widget.find('a.make-default').on('click', function () {
    //         $.post({
    //             url: `../ajax_dictionary_item/${$select.val()}/default`,
    //             success: build_select
    //         })
    //         return false;
    //     })
    //
    //     $widget.find('a.rename-item').on('click', function () {
    //         let $_modal = $('#edit_dictionary_item_code');
    //         let $_input = $_modal.find('input')
    //         $_modal.find('button[type="submit"]').off('click').on('click', function () {
    //             $.post({
    //                 url: `../ajax_dictionary_item/${$select.val()}/code`,
    //                 data: JSON.stringify({code: $_input.val()}),
    //                 contentType: "application/json",
    //                 success() {
    //                     UIkit.modal($_modal).hide();
    //                 }
    //             })
    //         })
    //         $_input.val($select.find("option:selected").text())
    //         UIkit.modal($_modal).show();
    //         return false;
    //     })
    //
    // });
    //
    // // комиксы
    //
    // let $comics = $('.comics');
    //
    // $comics.comics_widget({
    //     $select_image,
    //     images_url: `../comics?product_id=${$product_id.val()}`,
    //     on_add_instruction: l10n_show_modal
    // })
    // $comics.comics_widget('val', $('[name=comics]').val())
    //
    // $('#add-comics-image').on('click', function () {
    //     $comics.comics_widget('add_image')
    // })
    //
    // $('#add-comics-instruction').on('click', function () {
    //     $comics.comics_widget('add_instruction')
    // })
    //
    // $("input[type=file].js-uploader-comics").each(function (ind, elem) {
    //     $(elem).drop_uploader({
    //         uploader_text: 'Drop files to upload, or',
    //         browse_text: 'Browse',
    //         only_one_error_text: 'Only one file allowed',
    //         not_allowed_error_text: 'File type is not allowed',
    //         big_file_before_error_text: 'Files, bigger than',
    //         big_file_after_error_text: 'is not allowed',
    //         allowed_before_error_text: 'Only',
    //         allowed_after_error_text: 'files allowed',
    //         browse_css_class: 'button button-primary',
    //         browse_css_selector: 'file_browse',
    //         uploader_icon: '<i class="pe-7s-cloud-upload"></i>',
    //         file_icon: '<i class="pe-7s-file"></i>',
    //         progress_color: '#4a90e2',
    //         time_show_errors: 5,
    //         layout: 'thumbnails',
    //         method: 'normal'
    //     });
    // })
    //
    // // предпросмотр
    //
    // $('#create_preview').on('click', function () {
    //     $('#manual_preview').html('wait')
    //     var timer;
    //     $.post({
    //         url: '../preview/' + $("#manual_id").val(),
    //         success: function (resp) {
    //             let task_id = resp.task_id
    //             timer = setInterval(function () {
    //                 $.get({
    //                     url: '../preview/' + task_id,
    //                     success: function (data) {
    //                         if (data) {
    //                             $('#manual_preview').html(data)
    //                             clearInterval(timer)
    //                         }
    //                     },
    //                     error: function (resp) {
    //                         $('#manual_preview').html(resp.responseText)
    //                         clearInterval(timer)
    //                     }
    //                 })
    //             }, 3000)
    //         }
    //     })
    //     return false
    // })
    //
    // //
    //
    // $('.layout-widget').layout_widget({input: '#lang_block_layout'});
    //
    // // submit
    //
    // $form.on('submit', function () {
    //     $('[name=comics]').val($comics.comics_widget('val'))
    //     $locales.prop('disabled', false)
    // })
}