
export function banners(DFApp) {
    if (!DFApp.body.hasClass("js-banners-manage"))
        return;

    // Выбор изображения из товара
    $(".js-select-image").on("click", function(e) {
        e.preventDefault()
        let $image_id = $("input#image_id")

        if ($image_id) {
            let old_image_value = $image_id.val()
            $image_id.val($(this).data("image-id"))


            $(".js-select-image").each(function() {
                $(this).parent("li").removeClass("uk-active")
            })
            $(this).parent("li").addClass("uk-active")

            if (old_image_value == $(this).data("image-id")) {
                $(this).parent("li").removeClass("uk-active")
                $image_id.val(null)
            }
        }
    })

    $("select.js-select-banner-back").on("change", function(e) {
        let url = $(this).data("url")
        let back_id = $(this).val()

        let imageDiv = $(".js-back-preview-area")

        if (url && back_id) {
            $.ajax({
                url: url,
                data: {
                    "back_id": back_id
                },
                success: function(data) {
                    if (data) {
                        $(imageDiv).attr("data-src", data)
                    }
                },
                error(jqXHR, textStatus, errorThrown) {
                    $(imageDiv).attr("data-src", null)
                }
            })
        }
    })
}
