'use strict'

import "jquery-ui/ui/widgets/datepicker";

export function helpdesk_index(DFApp) {
    if (!DFApp.body.hasClass("js-helpdesk"))
        return;

    $("input[type=file].js-uploader-heldesk").each(function (ind, elem) {
        $(elem).drop_uploader({
            uploader_text: 'Drop files to upload, or',
            browse_text: 'Browse',
            only_one_error_text: 'Only one file allowed',
            not_allowed_error_text: 'File type is not allowed',
            big_file_before_error_text: 'Files, bigger than',
            big_file_after_error_text: 'is not allowed',
            allowed_before_error_text: 'Only',
            allowed_after_error_text: 'files allowed',
            browse_css_class: 'button button-primary',
            browse_css_selector: 'file_browse',
            uploader_icon: '<i class="pe-7s-cloud-upload"></i>',
            file_icon: '<i class="pe-7s-file"></i>',
            progress_color: '#4a90e2',
            time_show_errors: 5,
            layout: 'list',
            method: 'normal'
        });
    })

    $(".date-picker").datepicker({dateFormat: "dd.mm.yy", changeYear: true});

    let $form = $('form#corr_index')
    let $set_filter_button = $form.find('table thead').find('[name=set_filter]');
    let $filter_inputs = $form.find('table thead').find('select, input').not(".ignore-att");
    let $sorting_fields = $form.find('table thead').find('[data-sort_field]');
    let $select_all_rows = $form.find('table thead').find('input[name=select_correspondence_all]');
    let $select_table_row = $form.find('table tbody').find('[name=select_correspondence]');
    let $close_selected = $form.find('[name=close_selected]').parent();

    function set_sorting(field, asc) {
        if (!$sorting_fields.length)
            return;
        let $sort_input = $('input[name="sorting"]');
        if (asc == undefined) {
            var prev = $sort_input.val().split('|');
            if (field == undefined) {
                field = prev[0]
                asc = prev[1] == 'asc';
            } else
                asc = (prev[0] == field) ? (prev[1] != 'asc') : true;
        }
        $sort_input.val(field + '|' + (asc ? 'asc' : 'desc'));
        $sorting_fields.each(function (i, elm) {
            elm = $(elm)
            if (field == elm.attr('data-sort_field')) {
                elm.attr('uk-icon', (asc ? 'icon: chevron-down' : 'icon: chevron-up'));
                elm.addClass('uk-text-primary');
            } else {
                elm.attr('uk-icon', 'icon: chevron-down');
                elm.removeClass('uk-text-primary');
            }
        })
    }

    function set_attention_with_filter() {
        var $t = $(this)
        let val = $t.val();
        $t.toggleClass('with-filter', (val != '' && val != '__ALL'))
        $set_filter_button.prop("disabled", false);
    }

    function set_attention_with_filter_all() {
        $filter_inputs.each(set_attention_with_filter)
    }

    $sorting_fields.on('click', function () {
        set_sorting($(this).attr('data-sort_field'));
        $form.submit();
    })

    $filter_inputs.on('input change', set_attention_with_filter)

    $('#clear_filter').on('click', function (e) {
        e.preventDefault();
        $filter_inputs.filter('select').val('__ALL');
        $filter_inputs.filter('input').val('');
        set_sorting('start_date', true);
        set_attention_with_filter_all();
    });

    $select_table_row.on('change', function() {
        let selected_count = $select_table_row.filter(':checked').length;
        $select_all_rows.prop('checked', $select_table_row.length == selected_count);

        $close_selected.toggle(selected_count != 0);
    })

    $select_all_rows.on('change', function() {
        let do_select = $select_all_rows.prop('checked');
        $select_table_row.prop('checked', do_select);
        $close_selected.toggle(do_select);
    })

    set_attention_with_filter_all();
    set_sorting();
    $set_filter_button.prop("disabled", true);
}