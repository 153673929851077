'use strict'

export function faqs_index(DFApp) {
    if (!DFApp.body.hasClass("js-faqs-index"))
        return;

    let $form = $("form");
    let $filter_inputs = $form.find('table thead').find('select, input');
    let $set_filter = $form.find('[name=set_filter]');

    function set_attention_with_filter() {
        var $t = $(this)
        let val = $t.val();
        $t.toggleClass('with-filter', (val != '' && val != '__ALL'))
        $set_filter.prop("disabled", false);
    }

    function set_attention_with_filter_all() {
        $filter_inputs.each(set_attention_with_filter)
    }

    $filter_inputs.on('input', set_attention_with_filter)

    $('#clear_filter').on('click', function (e) {
        e.preventDefault();
        $form.find('select').val('__ALL');
        $form.find('input').val('');
        set_attention_with_filter_all();
    });

    set_attention_with_filter_all()
    $set_filter.prop("disabled", true);

    $('a.delete_faq').on('click', function(e) {
        if(!confirm($(e).attr('data-confirm')))
            return false
    })
}
