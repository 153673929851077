/* Import TinyMCE */
import "bootstrap"
import tinymce from 'tinymce';

/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default';

/* Required TinyMCE components */
import 'tinymce/themes/silver';
import 'tinymce/models/dom';

import 'tinymce-i18n/langs6/ru'

/* Import plugins */
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';


/* FileInput */
import "bootstrap-fileinput"
import "bootstrap-fileinput/js/locales/ru"

$(function() {
    // Редактор
    function tinymce_render (selector, preview=true) {
        return tinymce.init({
            selector: selector,
            plugins: 'advlist autoresize link lists table image fullscreen',
            toolbar: preview ? 'bold italic' : 'blocks | bold italic underline strikethrough | align bullist numlist | link image | table | fullscreen',
            skin: false,
            block_formats: 'Параграф=p; Заголовок 1=h1; Заголовок 2=h2; Заголовок 3=h3; Заголовок 4=h4;',
            content_css: false,
            promotion: false,
            branding: false,
            min_height: 200,
            language: 'ru',
            menubar: '',
            images_upload_url: $(selector).data("image-upload-dir"),
            //content_style: contentUiCss.toString() + '\n' + contentCss.toString(),
        })
    }
    tinymce_render("textarea.js-preview-editor-input");
    tinymce_render("textarea.js-content-editor-input", false);

    $("input.js-single-image-with-preview").each(function(n) {
        const val = $(this).data("img")
        let initialPreview = ""
        let initialPreviewConfig = {}
        let defaultPreviewContent = ""
        if (val){
            initialPreview = ["<img class='file-preview-image kv-preview-data' src='" + val + "' />"]
            initialPreviewConfig = [{
                url: $(this).data("delete-url"),
                key: $(this).data("fileid"),
                // extra: {
                //     fileid: $(this).data("fileid")
                // }
            }]
        }
        $(this).fileinput({
            language: "ru",
            overwriteInitial: true,
            showClose: false,
            showCaption: false,
            showRemove: false,
            browseLabel: '',
            removeLabel: '',
            maxFileCount: 1,
            browseIcon: '<i class="bi-folder2-open"></i>',
            removeIcon: '<i class="bi-x-lg"></i>',
            removeTitle: 'Cancel or reset changes',
            elErrorContainer: '#kv-avatar-errors-1',
            msgErrorClass: 'uk-alert uk-alert-block uk-alert-danger',
            initialPreview: initialPreview,
            defaultPreviewContent: defaultPreviewContent,
            initialPreviewShowDelete: true,
            initialPreviewConfig: initialPreviewConfig,
            deleteUrl: $(this).data("delete-url"),
            layoutTemplates: {main2: '{preview} {remove} {browse}'},
            allowedFileExtensions: ["png", "gif", "webp", "jpg", "jpeg"]
        })
        $(this)
    })
})