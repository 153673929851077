/* JS для работы со страницами ролей и прав доступа. */
export function rbac_main(body_tag) {
    if (body_tag.hasClass("js-rbac js-rbac-permissions")) {
        $("select.js-rbac-switcher").on("change", function() {
            let url = $(this).closest("form").data("switch-url");
            let category = $(this).data("category");

            if (url.length > 0 && category.length > 0) {
                let val = $(this).val();
                if (val && val !== "__None") {
                    window.location = url + '/' + category + '/' + val
                } else {
                    window.location = url
                }
            }
        })
    }
}