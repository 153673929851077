/* JS для работы со страницами ролей и прав доступа. */
import UIkit from "uikit";

export function products_files(DFApp) {
    if (DFApp.body.hasClass("js-products js-products-manage-files") ||
        DFApp.body.hasClass("js-products js-products-manage-product-file")) {
        $('.js-refresh-form').on('change', function(e) {
            $(this).closest("form").submit();
        })
    }
}