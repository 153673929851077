'use strict'

export function faqs(DFApp) {
    if (!DFApp.body.hasClass("js-faqs"))
        return;

    var $form = $("#faq_edit");

    $form.find('textarea.uk-textarea').each(function (i, e) {
        ClassicEditor.create(document.querySelector('textarea.uk-textarea[name=' + $(e).attr('name') + ']'))
    });

}
